import styled from 'styled-components';

const VerticalSpacer = styled.div`
  margin: ${(props) => props.theme.spacing.medium} 0;
`;

const HorizontalSpacer = styled.div`
  padding: 0 ${(props) => props.theme.spacing.small};

  @media all and (min-width: 600px) {
    padding: 0 ${(props) => props.theme.spacing.medium};
  }
`;

export { VerticalSpacer, HorizontalSpacer };
