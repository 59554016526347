import React from 'react';
import styled, { css } from 'styled-components';

const Form = styled.form``;

const Select = styled.select`
  background-color: ${(props) => props.theme.palette.background};
  border: 2px solid transparent;
  border-radius: 10px;
  color: ${(props) => props.theme.palette.text};
  height: 2.5rem;
  width: 100%;
  padding: 0 0.875rem;
  font-family: BrownStd;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3;
  appearance: none;
  outline: none;

  &:focus,
  &:active {
    border-color: ${(props) => props.theme.palette.secondary};
  }
`;

const Input = styled.input`
  background-color: ${(props) => props.theme.palette.background};
  border: 2px solid transparent;
  border-radius: 10px;
  color: ${(props) => props.theme.palette.text};
  height: 2.5rem;
  width: 100%;
  padding: 0 0.875rem;
  font-family: BrownStd;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3;
  outline: none;

  &:focus,
  &:active {
    border-color: ${(props) => props.theme.palette.secondary};
  }
`;

const Label = styled.label`
  line-height: 1.3;
  display: block;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text};
  margin-bottom: ${(props) => props.theme.spacing.xs};

  span {
    display: block;
  }

  .required {
    display: inline-block;
    color: ${(props) => props.theme.palette.primary};
  }
`;

const TextArea = styled.textarea`
  background-color: ${(props) => props.theme.palette.background};
  border: 2px solid transparent;
  border-radius: 10px;
  color: ${(props) => props.theme.palette.text};
  height: 12.5rem;
  width: 100%;
  padding: 0.875rem;
  font-family: BrownStd;
  font-weight: 700;
  line-height: 1.3;
  font-size: 0.875rem;
  outline: none;

  &:focus,
  &:active {
    border-color: ${(props) => props.theme.palette.secondary};
  }
`;

const ShortFieldWrapper = styled.div`
  display: flex;
  margin: -1rem;
`;

const Field = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};

  ${(props) =>
    props.short &&
    css`
      width: calc(50% - ${(props) => props.theme.spacing.xs});
      padding-top: 1rem;
      padding-bottom: 1rem;
    `};

  ${(props) =>
    props.spaceLeft &&
    css`
      padding-left: 1rem;
    `};

  ${(props) =>
    props.spaceRight &&
    css`
      padding-right: 1rem;
    `};

  &:not(:last-of-type) {
    margin-bottom: ${(props) => props.theme.spacing.large};
  }
`;

const CheckBoxLabel = styled.label`
  font-weight: 400;
  font-family: BrownStd;
  color: ${(props) => props.theme.palette.text};
  display: flex;

  span {
    margin-left: ${(props) => props.theme.spacing.xs};
  }

  a {
    color: ${(props) => props.theme.palette.primary};
  }

  .required {
    color: ${(props) => props.theme.palette.primary};
  }
`;
const CheckboxWrapper = styled.div``;

const Checkbox = ({ children, required, onChange }) => (
  <CheckboxWrapper>
    <CheckBoxLabel>
      <input required={required} onChange={onChange} type="checkbox" />
      <span>{children}</span>
    </CheckBoxLabel>
  </CheckboxWrapper>
);

export {
  Form,
  Input,
  Select,
  TextArea,
  Label,
  Checkbox,
  Field,
  ShortFieldWrapper,
};
