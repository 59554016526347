export default {
  successFormURL:
    'https://api.profiler.marketing/campaigns/5ed8dc44136bda00046555bc/collectors/5ed8dcbb136bda000465561b/collect',
  gtmId: 'GTM-MKJH88F',
};

const characteristics = {
  gender: '5ed8d8a8136bda00046553da',
  driverslicense: '5ed8d911136bda0004655407',
  availableWorkTimes: '5ed8daee136bda00046554d6',
  workGroup: '5ed8db3d136bda0004655509',
  travel: '5ed8db6a136bda0004655526',
  interests: '5ed8db7e136bda0004655534',
  interestComment: '5ed8dbc6136bda0004655560',
  about: '5ed8dbda136bda000465556e',
  region: '5ed8d9aa136bda0004655447',
  county: '5ed8d9be136bda0004655453',
  commune: '5ed8d9b9136bda000465544f',
  concession: '5fe06d0c3f44ba0004970a38',
  age: '61444a415aaacc0004039e99',
};

export { characteristics };
