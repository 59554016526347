import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: inline-block;
  padding: 0 1rem;
  margin: 1.4375rem 0;

  @media only screen and (max-width: ${props =>
      props.theme.mediaQueries.small}) {
    padding: 0 0.5rem;
  }
`;

export default ButtonWrapper;
