import styled from 'styled-components';
import hexToRgbA from '../utils/hexToRgba';

const Status = styled.div`
  border-radius: 10px;
  background-color: ${(props) =>
    hexToRgbA(props.theme.palette.primary, '0.15')};
  color: ${(props) => props.theme.palette.primary};
  padding: ${(props) => props.theme.spacing.small};
`;

export default Status;
