import React from 'react';
import Center from '../styleguide/Center';
import ContentWrapper from '../styleguide/ContentWrapper';
import { ButtonExternal } from '../styleguide/Button';
import Text from '../styleguide/Text';

function FormSent() {
  window.history.pushState(null, null, 'sent');

  return (
    <ContentWrapper>
      <Center>
        <Text
          variant="subtitle"
          color="primary"
          gutterBottom="medium"
          align="center"
        >
          Takk - din henvendelse er mottatt!
        </Text>
        <Text gutterBottom="large" align="center">
          Du vil nå motta en e-post fra oss med mer informasjon. Hvis du ikke
          mottar denne innen få minutter, vennligst sjekk spam-mappen din.
        </Text>

        <Center>
          <ButtonExternal href="https://stendi.no/bpa/vil-du-bli-assistent">
            Tilbake til Stendi Assistanse
          </ButtonExternal>
        </Center>
      </Center>
    </ContentWrapper>
  );
}

export default FormSent;
