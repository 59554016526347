import React from 'react';
import styled from 'styled-components';
import stendiLogo from '../utils/images/stendi_logo.png';

const HeaderWrapper = styled.header`
  height: 100px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.white};
  padding: ${(props) =>
    `${props.theme.spacing.small} ${props.theme.spacing.large}`};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;

  @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
    padding: ${(props) =>
      `${props.theme.spacing.small} ${props.theme.spacing.small}`};
  }
`;

const Logo = styled.a`
  width: 146px;

  img {
    height: 42px;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Logo href="https://stendi.no/bpa" aria-label="Gå til Stendi BPA">
        <img src={stendiLogo} alt="Stendi" />
      </Logo>
    </HeaderWrapper>
  );
};

export default Header;
