import styled from 'styled-components';

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 48.125rem;
  width: 100%;
  padding: 0 1.5rem;
`;

export default ContentWrapper;
