import { Container } from 'unstated';
import quizData from '../utils/quiz';

export default class QuizContainer extends Container {
  state = {
    quizData,
    started: false,
    currentStep: 0,
    completed: false,
    answers: [],
    sent: false,
  };

  getQuizData = () => {
    const { answers, quizData } = this.state;
    const shouldShowHiddenQuestion = answers.some(
      (f) => f.id === '3' && f.answer === 'false'
    );
    const filteredQuizData = quizData.filter((f) => f.id !== '4');
    const realQuizData = shouldShowHiddenQuestion ? quizData : filteredQuizData;

    return realQuizData;
  };

  nextStep = (answer) => {
    const { currentStep } = this.state;
    const quizData = this.getQuizData();

    if (currentStep === quizData.length - 1) {
      this.completeQuiz();

      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
        answers: [...prevState.answers, answer],
      }));

      // returning void here because
      // we don't want to trigger the
      // currentStep state transition
      // again.
      return;
    }

    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
      answers: [...prevState.answers, answer],
    }));
  };

  startQuiz = () => {
    this.setState({
      started: true,
    });
  };

  completeQuiz = () => {
    this.setState({
      completed: true,
    });
  };

  setSentForm = () => {
    this.setState({
      sent: true,
    });
  };
}
