export default [
  {
    id: '1',
    question: 'Er du over 18 år?',
    subText: '',
    step: 1,
    image: null,
  },
  {
    id: '2',
    question: 'Har du god vandel?',
    subText: '',
    step: 2,
    image: null,
  },
  {
    id: '3',
    question: 'Har du norsk som morsmål?',
    subText: '',
    step: 3,
    image: null,
  },
  {
    id: '4',
    question: 'Har du bestått språkprøve B1?',
    subText: '',
    step: 4,
    image: null,
  },
  {
    id: '5',
    question: 'Har du arbeidstillatelse?',
    subText: '(Er du norsk statsborger har du automatisk arbeidstillatelse.)',
    step: 5,
    image: null,
  },
  {
    id: '6',
    question: 'Ønsker du å bidra til å gi noen en bedre hverdag?',
    subText: '',
    step: 5,
    image: null,
  },
];
