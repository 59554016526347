import React from 'react';
import styled from 'styled-components';
import Button from '../styleguide/Button';
import Center from '../styleguide/Center';
import ButtonWrapper from '../styleguide/ButtonWrapper';
import Progresser from './Progresser';
import { HorizontalSpacer, VerticalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';

const IllustrationWrapper = styled.div`
  position: absolute;
  width: 200px;
  bottom: -4rem;
  left: 4rem;

  img {
    width: 100%;
  }

  @media all and (max-width: ${(props) => props.theme.mediaQueries.medium}) {
    position: static;
  }
`;

function Quiz({ quizData, currentStep, nextStep }) {
  const current = quizData[currentStep];
  const illustration = quizData[currentStep].image;
  const quizButtonYesRef = React.createRef();
  const quizButtonNoRef = React.createRef();

  window.history.pushState(null, null, `${currentStep + 1}`);

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block centered tighter>
                <Center>
                  <Progresser />
                </Center>
                <Text
                  variant="subtitle"
                  color="primary"
                  align="center"
                  gutterBottom="medium"
                  gutterTop="xlarge"
                >
                  {current.question}
                </Text>
                {current.subText && (
                  <Text bold align="center" gutterBottom="large">
                    {current.subText}
                  </Text>
                )}

                <ButtonWrapper>
                  <Button
                    outlined
                    ref={quizButtonYesRef}
                    title="Ja"
                    onClick={() => {
                      nextStep({
                        id: current.id,
                        answer: 'true',
                      });
                      quizButtonYesRef.current.blur();
                    }}
                  >
                    Ja
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    outlined
                    ref={quizButtonNoRef}
                    title="Nei"
                    onClick={() => {
                      nextStep({
                        id: current.id,
                        answer: 'false',
                      });
                      quizButtonNoRef.current.blur();
                    }}
                  >
                    Nei
                  </Button>
                </ButtonWrapper>
              </Block>
              {illustration && (
                <IllustrationWrapper>
                  <img src={illustration} alt="Illustrasjon" />
                </IllustrationWrapper>
              )}
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Quiz;
