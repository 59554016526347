import styled, { css } from 'styled-components';

const ContentSizer = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;

  ${(props) =>
    props.size === 'medium' &&
    css`
      max-width: 960px;
    `};
`;

export default ContentSizer;
