import React, { Component } from 'react';
import {
  Form,
  Input,
  Label,
  Checkbox,
  Select,
  Field,
  TextArea,
  ShortFieldWrapper,
} from '../styleguide/Form';
import Button from '../styleguide/Button';
import receivers from '../utils/receivers';
import Search from './Downshift';
import Text from '../styleguide/Text';
import MultiField from '../styleguide/MultiField';
import config, { characteristics } from '../utils/config';
import Status from '../styleguide/Status';

class SuccessForm extends Component {
  state = {
    name: '',
    phone: '',
    email: '',
    commune: '',
    region: '',
    county: '',
    concession: '',
    gender: '',
    age: '',
    driverslicense: '',
    availableWorkTimes: [],
    workGroup: '',
    travel: '',
    interests: [],
    interestComment: '',
    about: '',
    consentBasic: undefined,
    consentEmail: undefined,
    formError: false,
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleSelect = (region, commune, county, concession) => {
    this.setState({
      region: region,
      commune: commune,
      county: county,
      concession: concession ? 'true' : 'false',
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      email,
      region,
      commune,
      county,
      concession,
      phone,
      name,
      gender,
      age,
      availableWorkTimes,
      workGroup,
      travel,
      interests,
      interestComment,
      about,
      driverslicense,
      consentBasic,
      consentEmail,
    } = this.state;
    const { setSentForm } = this.props;
    const url = config.successFormURL;

    const data = {
      email,
      name,
      phone,
      [characteristics.gender]: gender,
      [characteristics.age]: age,
      [characteristics.driverslicense]: driverslicense,
      [characteristics.availableWorkTimes]: availableWorkTimes.join(', '),
      [characteristics.workGroup]: workGroup,
      [characteristics.travel]: travel,
      [characteristics.interests]: interests.join(', '),
      [characteristics.interestComment]: interestComment,
      [characteristics.about]: about,
      [characteristics.region]: region,
      [characteristics.county]: county,
      [characteristics.commune]: commune,
      [characteristics.concession]: concession,
      'consent:basic': consentBasic,
      'consent:email': consentEmail,
    };

    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      setSentForm();
    } else {
      this.setState({
        formError: true,
      });
    }
  };

  handleMultiSelection = (fieldName, value, checked) => {
    this.setState({
      [fieldName]: !checked
        ? this.state[fieldName].filter((f) => f !== value)
        : [...this.state[fieldName], value],
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Field>
          <Label htmlFor="name">
            <span>
              Navn <span className="required">*</span>
            </span>
          </Label>
          <Input
            type="text"
            label="Navn"
            placeholder="Skriv inn ditt fulle navn"
            name="name"
            id="name"
            required
            onChange={this.handleInputChange}
          />
        </Field>

        <Field>
          <Label htmlFor="phone">
            <span>
              Telefon <span className="required">*</span>
            </span>
          </Label>
          <Input
            type="text"
            label="Telefonnummer"
            placeholder="Skriv inn ditt telefonnummer"
            name="phone"
            id="phone"
            required
            onChange={this.handleInputChange}
          />
        </Field>
        <ShortFieldWrapper>
          <Field short spaceRight spaceLeft>
            <Label htmlFor="email">
              <span>
                E-post <span className="required">*</span>
              </span>
            </Label>
            <Input
              type="email"
              label="E-post"
              placeholder="Skriv inn din e-postadresse"
              name="email"
              id="email"
              required
              onChange={this.handleInputChange}
            />
          </Field>
          <Field short>
            <Label htmlFor="age">
              <span>
                Alder <span className="required">*</span>
              </span>
            </Label>
            <Select
              id="age"
              value={this.state.age}
              onChange={(e) => {
                this.setState({
                  age: e.target.value,
                });
              }}
              required
            >
              <option value="">Vennligst velg…</option>
              <option value="18-20">18-20</option>
              <option value="21-25">21-25</option>
              <option value="26-30">26-30</option>
              <option value="31-35">31-35</option>
              <option value="36-40">36-40</option>
              <option value="41-45">41-45</option>
              <option value="46-50">46-50</option>
              <option value="51-55">51-55</option>
              <option value="56-60">56-60</option>
              <option value="61-65">61-65</option>
              <option value="66-70">66-70</option>
              <option value="71-75">71-75</option>
            </Select>
          </Field>
        </ShortFieldWrapper>
        <Field>
          <Label htmlFor="county">
            <span>
              Kommune <span className="required">*</span>
            </span>
          </Label>
          <Search handleSelect={this.handleSelect} items={receivers} />
        </Field>
        <ShortFieldWrapper>
          <Field short spaceRight spaceLeft>
            <Label htmlFor="gender">
              <span>
                Kjønn <span className="required">*</span>
              </span>
            </Label>
            <Select
              id="gender"
              value={this.state.gender}
              onChange={(e) => {
                this.setState({
                  gender: e.target.value,
                });
              }}
              required
            >
              <option value="">Vennligst velg…</option>
              <option value="Kvinne">Kvinne</option>
              <option value="Mann">Mann</option>
              <option value="Annet">Annet</option>
            </Select>
          </Field>
          <Field short>
            <Label htmlFor="driverslicense">
              <span>
                Sertifikat klasse B <span className="required">*</span>
              </span>
            </Label>
            <Select
              id="driverslicense"
              value={this.state.driverslicense}
              onChange={(e) => {
                this.setState({
                  driverslicense: e.target.value,
                });
              }}
              required
            >
              <option value="">Vennligst velg…</option>
              <option value="Ja">Ja</option>
              <option value="Nei">Nei</option>
              <option value="Automatgir">Kun automatgir</option>
            </Select>
          </Field>
        </ShortFieldWrapper>

        <MultiField
          wrappingLabel="Når har du mulighet for å jobbe? Du kan huke av flere bokser"
          required
          fields={[
            {
              id: 'daytime',
              type: 'checkbox',
              label: 'Dagtid',
              value: 'Dagtid',
              required: this.state.availableWorkTimes.length === 0,
              onChange: (e) =>
                this.handleMultiSelection(
                  'availableWorkTimes',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'afternoon_night',
              type: 'checkbox',
              label: 'Ettermiddag/kveld',
              value: 'Ettermiddag/kveld',
              required: this.state.availableWorkTimes.length === 0,
              onChange: (e) =>
                this.handleMultiSelection(
                  'availableWorkTimes',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'night',
              type: 'checkbox',
              label: 'Natt',
              value: 'Natt',
              required: this.state.availableWorkTimes.length === 0,
              onChange: (e) =>
                this.handleMultiSelection(
                  'availableWorkTimes',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'weekend',
              type: 'checkbox',
              label: 'Helg',
              value: 'Helg',
              required: this.state.availableWorkTimes.length === 0,
              onChange: (e) =>
                this.handleMultiSelection(
                  'availableWorkTimes',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'red_days',
              type: 'checkbox',
              label: 'Helligdager',
              value: 'Helligdager',
              required: this.state.availableWorkTimes.length === 0,
              onChange: (e) =>
                this.handleMultiSelection(
                  'availableWorkTimes',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'holidays',
              type: 'checkbox',
              label: 'Ferier',
              value: 'Ferier',
              required: this.state.availableWorkTimes.length === 0,
              onChange: (e) =>
                this.handleMultiSelection(
                  'availableWorkTimes',
                  e.target.value,
                  e.target.checked
                ),
            },
          ]}
        />

        <MultiField
          wrappingLabel="Hvilke mennesker ønsker du å jobbe med?"
          required
          fields={[
            {
              id: 'children',
              type: 'radio',
              label: 'Barn',
              name: 'workGroup',
              value: 'Barn',
              required: true,
              onChange: this.handleInputChange,
            },
            {
              id: 'adults',
              type: 'radio',
              label: 'Voksne',
              name: 'workGroup',
              value: 'Voksne',
              required: true,
              onChange: this.handleInputChange,
            },
            {
              id: 'both',
              type: 'radio',
              label: 'Begge deler',
              name: 'workGroup',
              value: 'Både barn og voksne',
              required: true,
              onChange: this.handleInputChange,
            },
          ]}
        />

        <MultiField
          wrappingLabel="Er du åpen for reising i jobbsammenheng?"
          required
          fields={[
            {
              id: 'travel_yes',
              type: 'radio',
              label: 'Ja',
              name: 'travel',
              value: true,
              required: true,
              onChange: this.handleInputChange,
            },
            {
              id: 'travel_no',
              type: 'radio',
              label: 'Nei',
              name: 'travel',
              value: false,
              required: true,
              onChange: this.handleInputChange,
            },
          ]}
        />

        <MultiField
          wrappingLabel="Hva er dine interesser? Du kan utdype eller skrive flere interesser i kommentarfeltet"
          fields={[
            {
              id: 'music',
              type: 'checkbox',
              label: 'Musikk',
              value: 'Musikk',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'outdoors',
              type: 'checkbox',
              label: 'Friluftsliv',
              value: 'Friluftsliv',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'gardening',
              type: 'checkbox',
              label: 'Hage/planter',
              value: 'Hage/planter',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'movies',
              type: 'checkbox',
              label: 'Film/TV',
              value: 'Film/TV',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'gaminig',
              type: 'checkbox',
              label: 'Gaming',
              value: 'Gaming',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'cooking',
              type: 'checkbox',
              label: 'Matlaging',
              value: 'Matlaging',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'travel',
              type: 'checkbox',
              label: 'Reiser',
              value: 'Reiser',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
            {
              id: 'workout',
              type: 'checkbox',
              label: 'Trening',
              value: 'Trening',
              onChange: (e) =>
                this.handleMultiSelection(
                  'interests',
                  e.target.value,
                  e.target.checked
                ),
            },
          ]}
        />

        <Field>
          <TextArea
            placeholder="Fortell oss om dine interesser, maks 150 tegn."
            maxLength={150}
            name="interestComment"
            id="interestComment"
            onChange={this.handleInputChange}
          />
        </Field>

        <Field>
          <Label>
            <span>
              Fortell litt om deg selv, og hvorfor du tror du egner deg som
              assistent <span className="required">*</span>
            </span>
          </Label>
          <TextArea
            placeholder="Forklar oss hvem du er og hvorfor du bør bli assistent, maks 900 tegn."
            name="about"
            id="about"
            required
            maxLength={900}
            onChange={this.handleInputChange}
          />
        </Field>

        <Field>
          <Checkbox
            required={true}
            onChange={(e) => {
              this.setState({
                consentBasic: e.target.checked,
              });
            }}
          >
            Jeg godtar at min søknad vil bli delt med arbeidsledere som trenger
            personlig assistent, og at Stendi behandler mine personopplysninger
            i tråd med{' '}
            <a
              target="_blank"
              href="https://res.cloudinary.com/rubics/image/upload/v1580221629/Personvernerklaering_Stendi_bzxm7t.pdf"
              rel="noopener noreferrer"
            >
              personvernerklæringen
            </a>
            <span className="required">*</span>
          </Checkbox>
        </Field>
        <Field>
          <Checkbox
            onChange={(e) => {
              this.setState({
                consentEmail: e.target.checked,
              });
            }}
          >
            Jeg ønsker å motta relevante nyhetsbrev fra Stendi Assistanse
          </Checkbox>
        </Field>
        <Field>
          <Text>
            <Text element="span" color="primary">
              *
            </Text>{' '}
            Feltet må fylles ut.
          </Text>
        </Field>
        {this.state.formError && (
          <Field>
            <Status>
              Noe gikk galt ved innsending av skjema. Vennligst prøv igjen ved å
              oppdatere nettleservinduet, eller vent noen minutter før du prøver
              på nytt.
            </Status>
          </Field>
        )}

        <Field>
          <Button type="submit" fullWidth>
            Send
          </Button>
        </Field>
      </Form>
    );
  }
}

export default SuccessForm;
