import React from 'react';
import styled from 'styled-components';
import Downshift from 'downshift';
import { Input } from '../styleguide/Form';
import theme from '../utils/theme';

const RelativeWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  * > div {
    position: relative;
  }
`;

const Options = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  font-family: BrownStd;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3;
  position: absolute;
  width: 100%;
  z-index: 1;
  background: ${(props) => props.theme.palette.white};
  max-height: 15rem;
  overflow-y: scroll;
`;

const Option = styled.li`
  cursor: pointer;
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};
  color: transparent;
  font-weight: 400;
  font-size: 1.25rem;
`;

const Search = ({ items, handleSelect }) => {
  return (
    <Downshift
      onChange={(selection) => {
        handleSelect(
          selection.region,
          selection.commune,
          selection.county,
          selection.concession
        );
      }}
      itemToString={(item) => (item ? item.commune : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <RelativeWrapper {...getRootProps()}>
          <Wrapper>
            <Input
              placeholder="Skriv inn kommune"
              required
              type="text"
              name="county"
              id="county"
              withLabelSpace
              {...getInputProps()}
            />
          </Wrapper>
          <Options {...getMenuProps()}>
            {isOpen
              ? items
                  .filter(
                    (item) =>
                      !inputValue ||
                      item.commune.toLowerCase().includes(inputValue) ||
                      item.commune.includes(inputValue)
                  )
                  .sort((a, b) => (a.commune > b.commune ? 1 : -1))
                  .map((item, index) => (
                    <Option
                      {...getItemProps({
                        key: index,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index
                              ? theme.palette.background
                              : 'white',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                          color:
                            highlightedIndex === item
                              ? theme.palette.white
                              : theme.palette.text,
                        },
                      })}
                    >
                      {`${item.commune} (${item.county})`}
                    </Option>
                  ))
              : null}
          </Options>
        </RelativeWrapper>
      )}
    </Downshift>
  );
};

export default Search;
