import React from 'react';
import styled from 'styled-components';
import { Subscribe } from 'unstated';
import QuizContainer from '../containers/QuizContainer';
import stepCalc from '../utils/stepCalc';

const StyledProgressWrapper = styled.div`
  box-sizing: border-box;
  height: 7px;
  width: 337px;
  background-color: ${(props) => props.theme.palette.background};
  position: relative;
  border-radius: 100px;

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQueries.small}) {
    width: 270px;
    text-align: center;
  }
`;

const StyledInnerProgresser = styled.div`
  width: ${(props) => props.width}%;
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  background-color: ${(props) => props.theme.palette.secondary};
  border-radius: 100px;
`;

const ResultText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.palette.text};
`;

const Step = styled.p`
  color: ${(props) => props.theme.palette.text};

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQueries.small}) {
    text-align: center;

    span:first-child {
      font-weight: 700;
    }

    span:nth-child(2) {
      display: inline;
    }
  }
`;

export default function Progresser() {
  return (
    <Subscribe to={[QuizContainer]}>
      {({ state, getQuizData }) => {
        const quizData = getQuizData();

        return (
          <div>
            {state.currentStep < quizData.length ? (
              <Step>
                <span>{` ${state.currentStep + 1}/ ${quizData.length}`}</span>{' '}
                <span>Kan jeg bli assistent?</span>
              </Step>
            ) : (
              <ResultText>Resultat</ResultText>
            )}

            <StyledProgressWrapper>
              <StyledInnerProgresser
                width={stepCalc(state.currentStep, quizData)}
              />
            </StyledProgressWrapper>
          </div>
        );
      }}
    </Subscribe>
  );
}
