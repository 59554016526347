const receivers = [
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Bærum',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Aurskog-Høland',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Asker',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Aremark',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Oslo',
    commune: 'Oslo',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Lillestrøm',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Indre Østfold',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Hvaler',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Hurdal',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Halden',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Gjerdrum',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Frogn',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Fredrikstad',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Enebakk',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Eidsvoll',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Rælingen',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Rakkestad',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nordre Follo',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nittedal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nesodden',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Nannestad',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Moss',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Marker',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Lørenskog',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Eidskog',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Dovre',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Alvdal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Ås',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Våler',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Vestby',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Ullensaker',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Skiptvet',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Sarpsborg',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Viken',
    commune: 'Råde',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Jevnaker',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Hamar',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Grue',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Gran',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Gjøvik',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Gausdal',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Folldal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Etnedal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Engerdal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Elverum',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nordre Land',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nord-Odal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nord-Fron',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Nord-Aurdal',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Løten',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lunner',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lom',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lillehammer',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Lesja',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Kongsvinger',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Stor-Elvdal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Stange',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Skjåk',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sel',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Ringsaker',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Ringebu',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Rendalen',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Os',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vestre Toten',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vestre Slidre',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vang',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Tynset',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Trysil',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Trysil',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Tolga',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sør-Odal',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sør-Fron',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Sør-Aurdal',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Søndre Land',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Åsnes',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Åmot',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Øystre Slidre',
    concession: false,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Øyer',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Østre Toten',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Våler',
    concession: true,
  },
  {
    region: 'Øst',
    county: 'Innlandet',
    commune: 'Vågå',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Harstad',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Hammerfest',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Gratangen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Gamvik',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Dyrøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Båtsfjord',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Berlevåg',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Bardu',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Balsfjord',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Alta',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Lebesby',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Lavangen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kåfjord',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kvænangen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kvæfjord',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Kautokeino',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Karlsøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Karasjok',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Ibestad',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Hasvik',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Senja',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Salangen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Porsanger',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Nordreisa',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Nordkapp',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Nesseby',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Måsøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Målselv',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Lyngen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Loppa',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Vardø',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Vadsø',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Tromsø',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Tana',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Sørreisa',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Sør-Varanger',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Storfjord',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Troms og Finnmark',
    commune: 'Skjervøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Evenes',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Dønna',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Bø',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Brønnøy',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Bodø',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Bindal',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Beiarn',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Andøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Alstahaug',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hattjelldal',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hamarøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hadsel',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Grane',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Gildeskål',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Flakstad',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Fauske',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Narvik',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Moskenes',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Meløy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Lødingen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Lurøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Leirfjord',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Herøy',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Hemnes',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Sørfold',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Sønma',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Steigen',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Sortland',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Saltdal',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Røst',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Rødøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Rana',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Nesna',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Flå',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Flesberg',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Drammen',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Øksnes',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vågan',
    concession: true,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Værøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vevelstad',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vestvågøy',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vega',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Vefsn',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Træna',
    concession: false,
  },
  {
    region: 'Nord',
    county: 'Nordland',
    commune: 'Tjeldsund',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Nore og Uvdal',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Nesbyen',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Nes',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Modum',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Lier',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Krødsherad',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Kongsberg',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Hole',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Hol',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Hemsedal',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Gol',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Rollag',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Ringerike',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Færder',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Fyresdal',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Drangedal',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Bamble',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Ål',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Øvre Eiker',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Viken',
    commune: 'Sigdal',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Porsgrunn',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Notodden',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Nome',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Nissedal',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Midt-Telemark',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Larvik',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Kviteseid',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Kragerø',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Horten',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Holmestrand',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Hjartdal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Birkenes',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Arendal',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Vinje',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Tønsberg',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Tokke',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Tinn',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Skien',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Siljan',
    concession: true,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Seljord',
    concession: false,
  },
  {
    region: 'Sør',
    county: 'Vestfold og Telemark',
    commune: 'Sandefjord',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Kristiansand',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Iveland',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Hægebostad',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Grimstad',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Gjerstad',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Froland',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Flekkefjord',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Farsund',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Evje og Hornnes',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Bykle',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Bygland',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Lindesnes',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Lillesand',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Kvinesdal',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Eigersund',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Bokn',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Bjerkreim',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Åseral',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Åmli',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Vennesla',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Vegårshei',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Valle',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Tvedestrand',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Sirdal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Risør',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Agder',
    commune: 'Lyngdal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Randaberg',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Lund',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Kvitsøy',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Klepp',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Karmøy',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Hå',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Hjelmeland',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Haugesund',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Gjesdal',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Alver',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Vindafjord',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Utsira',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Tysvær',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Time',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Suldal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Strand',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Stavanger',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sola',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sokndal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sauda',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Rogaland',
    commune: 'Sandnes',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Etne',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Eidfjord',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bømlo',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bremanger',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bjørnafjorden',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Bergen',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Austrheim',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Austevoll',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Aurland',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Askøy',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Askvoll',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kvinnherad',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kvam',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kvam',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Kinn',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Høyanger',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Hyllestad',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Gulen',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Gloppen',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Fjaler',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Fitjar',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Fedje',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Stryn',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Stord',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Stad',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Solund',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Sogndal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Samnanger',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Osterøy',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Modalen',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Masfjorden',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Lærdal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Luster',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Aure',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Aukra',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Årdal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Øygarden',
    concession: true,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Voss',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Vik',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Vaksdal',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Ulvik',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Ullensvang',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Tysnes',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Sveio',
    concession: false,
  },
  {
    region: 'Vest',
    county: 'Vestland',
    commune: 'Sunnfjord',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Rindal',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Rauma',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Molde',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Kristiansund',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Hustadvika',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Herøy',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Hareid',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Gjemnes',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Giske',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Fjord',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Averøy',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Volda',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Vestnes',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Vanylven',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Ulstein',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Tingvoll',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sykkylven',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Surnadal',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sunndal',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sula',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Stranda',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Smøla',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Sande',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Indre Fosen',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Inderøy',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Høylandet',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Holtålen',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Hitra',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Heim',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Grong',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Frøya',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Frosta',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Flatanger',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Ålesund',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Møre og Romsdal',
    commune: 'Ørsta',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Melhus',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Malvik',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Lierne',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Levanger',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Leka',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Osen',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Orkland',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Oppdal',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Nærøysund',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Namsskogan',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Namsos',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Midtre Gauldal',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Meråker',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Verdal',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Tydal',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Trondheim',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Stjørdal',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Steinkjer',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Snåsa',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Skaun',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Selbu',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Røyrvik',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Røros',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Rennebu',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Overhalla',
    concession: false,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Åfjord',
    concession: true,
  },
  {
    region: 'Midt-Norge',
    county: 'Trøndelag',
    commune: 'Ørland',
    concession: false,
  },
];

export default receivers;
