import React, { Fragment } from 'react';
import Center from '../styleguide/Center';
import Progresser from './Progresser';
import { VerticalSpacer } from '../styleguide/Spacer';
import SuccessForm from './SuccessForm';
import FormSent from './FormSent';
import { HorizontalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';
import { ButtonExternal } from '../styleguide/Button';

function Result({ answers, sent, setSentForm, quizData }) {
  let failed = false;

  for (let i = 0; i < answers.length; i++) {
    const item = answers[i];

    if (item.id === '3' && item.answer === 'false') {
      continue;
    }

    if (item.id === '4' && item.answer === 'false') {
      failed = true;
    }

    if (item.answer === 'false') {
      failed = true;
    }
  }

  const failedTitle = 'Takk for at du deltok!';
  const successTitle = 'Det var en god start!';

  if (failed) {
    window.history.pushState(null, null, 'x');
  } else {
    window.history.pushState(null, null, 'success');
  }

  const successSubtitle =
    'Du har gode muligheter til å kunne få jobb som personlig assistent. Vi vil gjerne vite mer om deg. Fyll ut skjemaet under, og vi vil ta kontakt dersom du er aktuell til en av våre stillinger.';

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block tighter>
                {sent ? (
                  <FormSent />
                ) : (
                  <Fragment>
                    <Center>
                      <Progresser />
                    </Center>
                    <Text
                      variant="subtitle"
                      color="primary"
                      gutterTop="xlarge"
                      gutterBottom="large"
                      align="center"
                    >
                      {failed ? failedTitle : successTitle}
                    </Text>
                    {!failed && (
                      <Text align="center" gutterBottom="large" width="60%">
                        {successSubtitle}
                      </Text>
                    )}

                    {failed && (
                      <Fragment>
                        <Text align="center" gutterBottom="large" width="55%">
                          Basert på svarene dine ser det dessverre ikke ut til
                          at du oppfyller våre kriterier for å kunne jobbe som
                          personlig assistent. Du kan lese mer om hvordan det er
                          å jobbe som personlig assistent på{' '}
                          <a
                            target="_blank"
                            href="https://stendi.no/bpa/assistent"
                            rel="noopener noreferrer"
                          >
                            vår nettside.
                          </a>
                        </Text>
                        <Center>
                          <ButtonExternal href="https://stendi.no/bpa/assistent">
                            Tilbake til Stendi nettsiden
                          </ButtonExternal>
                        </Center>
                      </Fragment>
                    )}
                    {!failed && (
                      <Fragment>
                        <SuccessForm
                          quizData={quizData}
                          answers={answers}
                          setSentForm={setSentForm}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Block>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Result;
