import styled, { css } from 'styled-components';

const ButtonStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 0 2rem;
  border-radius: 1.75rem;
  text-decoration: none;
  min-width: 150px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  color: ${(props) => props.theme.palette.white};
  background-color: ${(props) => props.theme.palette.primary};
  font-family: BrownStd;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.6;

  ${(props) =>
    props.outlined &&
    css`
      border-color: ${props.theme.palette.primary};
      color: ${props.theme.palette.text};
      background-color: ${props.theme.palette.white};

      @media all and (min-width: ${(props) => props.theme.mediaQueries.small}) {
        &:hover {
          color: ${(props) => props.theme.palette.white};
          background-color: ${(props) => props.theme.palette.primary};
        }
      }
    `};

  @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
    min-width: 120px;
  }
`;

const Button = styled.button`
  ${ButtonStyles};
`;

const ButtonExternal = styled.a`
  ${ButtonStyles};
`;

export default Button;
export { ButtonExternal };
