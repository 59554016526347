import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const FakeLabel = styled.p`
  line-height: 1.3;
  display: block;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text};
  margin-bottom: ${(props) => props.theme.spacing.xs};

  .required {
    color: ${(props) => props.theme.palette.primary};
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 1rem;

    @media all and (max-width: ${(props) => props.theme.mediaQueries.small}) {
      margin-top: 1rem;
    }
  }
`;

const InputFieldWrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-right: 0.5rem;
  }
`;

export default function MultiField({ fields = [], wrappingLabel, required }) {
  return (
    <Wrapper>
      <FakeLabel>
        {wrappingLabel} {required && <span className="required">*</span>}
      </FakeLabel>
      {fields && fields.length > 0 && (
        <FieldWrapper>
          {fields.map((field, index) => {
            return (
              <InputFieldWrapper key={index}>
                <input
                  id={field.id}
                  type={field.type}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={field.placeholder}
                  required={field.required}
                  name={field.name}
                />
                <label htmlFor={field.id}>{field.label}</label>
              </InputFieldWrapper>
            );
          })}
        </FieldWrapper>
      )}
    </Wrapper>
  );
}
