import React, { Component, Fragment } from 'react';
import { Subscribe } from 'unstated';
import Home from './Home';
import Quiz from './Quiz';
import Result from './Result';
import QuizContainer from '../containers/QuizContainer';
import GoogleTagManager from '../utils/GoogleTagManager';
import Header from './Header';
import config from '../utils/config';

class App extends Component {
  render() {
    return (
      <Fragment>
        <GoogleTagManager gtmId={config.gtmId} />
        <Header />
        <Subscribe to={[QuizContainer]}>
          {({ state, startQuiz, nextStep, setSentForm, getQuizData }) => {
            if (!state.started && !state.completed)
              return <Home startQuiz={startQuiz} />;

            if (state.started && !state.completed)
              return (
                <Quiz
                  quizData={getQuizData()}
                  currentStep={state.currentStep}
                  nextStep={nextStep}
                />
              );

            if (state.started && state.completed)
              return (
                <Result
                  sent={state.sent}
                  setSentForm={setSentForm}
                  answers={state.answers}
                  quizData={state.quizData}
                />
              );

            return null;
          }}
        </Subscribe>
      </Fragment>
    );
  }
}

export default App;
